@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;1,700&display=swap);
.leaflet-container {
  width: 100%;
  height: 100vh;
}

.loading-modal {
  width: 7rem;
  height: 4rem;
  background-color: white;
  display: flex;
  align-items: center;
  box-shadow: 5px 10px;
  border: 1px solid black;
}

.loading-text {
  width: 100%;
  text-align: center;
}

.loading-box {
  position: absolute;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.brag-modal {
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  z-index: 1000;

  background-color: white;

  box-shadow: 5px 10px;
  border: 1px solid black;

  margin-bottom: 1rem;
  padding: 0.5rem;
}

.brag-text {
  display: block;
  margin: 0.25rem;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
}

