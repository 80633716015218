.leaflet-container {
  width: 100%;
  height: 100vh;
}

.loading-modal {
  width: 7rem;
  height: 4rem;
  background-color: white;
  display: flex;
  align-items: center;
  box-shadow: 5px 10px;
  border: 1px solid black;
}

.loading-text {
  width: 100%;
  text-align: center;
}

.loading-box {
  position: absolute;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
